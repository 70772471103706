<template>
  <div v-if="brands" class="login-brands">
    <div
      v-for="(brand, index) in brands"
      :key="index"
      :class="['login-brands__item', { 'login-brands__item--active': brand.slug === activeBrand }]"
      @click="selectBrand(brand.slug)"
    >
      <div class="login-brands__inner">
        <img
          class="login-brands__logo"
          :src="require(`@/assets/Images/Logos/${brand.slug}.svg`)"
          :alt="`logo-brand-${brand.slug}`"
        />
        <div class="login-brands__check" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LoginBrands',
  data: () => ({
    activeBrand: '',
  }),
  computed: {
    ...mapState('Settings', ['brands']),
  },
  mounted() {
    this.getBrandListRequest().then(() => {
      if (this.brands.length) {
        this.selectBrand(this.brands[0].slug);
      }
    });
  },
  methods: {
    ...mapActions('Settings', ['getBrandListRequest']),
    selectBrand(brand) {
      this.activeBrand = brand;
      this.$emit('select-brand', brand);
    },
  },
};
</script>

<style lang="scss">
.login-brands {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 4px;
  height: 53px;

  &__item {
    padding: 2px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    &--active {
      background-color: #0452c826;
    }
  }

  &__inner {
    border-radius: $base-border-radius * 5;
    border: 2px solid #f2f2f5;
    background: var(--app-body-color);
    height: 49px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 100%;
    max-width: 110px;
  }

  &__item--active &__inner {
    border-color: var(--app-brand-default-color);
  }

  &__name {
    font-size: $font-size-bigger;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__check {
    width: 12px;
    height: 12px;
    background: #f9f9fb;
    border-radius: 50%;
    border: 1px solid #f2f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__item--active &__check {
    border-color: var(--app-brand-default-color);
    background: var(--app-brand-default-color) url('~@/assets/Images/Icons/Checkbox/default.svg')
      no-repeat center;
    background-size: 8px 6px;
  }
}
</style>