<template>
  <div class="app-brand">
    <img class="app-brand__logo" :src="require(`@/assets/Images/Logos/${icon}.svg`)" :alt="icon" />
  </div>
</template>

<script>
export default {
  name: 'Brand',
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.app-brand {
  &__logo {
    width: 118px;
  }
}
</style>