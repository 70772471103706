import { AuthGuard } from '@/router/Auth-guard';

export default [
  {
    path: '/questionary/start',
    name: 'api:questionary:start',
    props: true,
    beforeEnter: AuthGuard.redirectToTestingPage,
  },
  {
    path: '/questionary/testing',
    redirect: { name: 'questionary:testing' },
  },
  {
    path: '*',
    props: true,
    beforeEnter: AuthGuard.redirectToHome,
  },
];
