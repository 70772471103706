import Vue from 'vue';
import { Api } from '@/services/General/Api';

const theme = localStorage.getItem('theme');
const brand = localStorage.getItem('brand');

export default {
  namespaced: true,
  state: {
    isDarkTheme: theme === 'Dark',
    brand: brand || '',
    brands: [],
    toggleMenu: false,
    platform: 'desktop',
  },
  mutations: {
    toggleMenu(state, flag) {
      state.toggleMenu = flag;
    },
    setDarkTheme(state, flag) {
      state.isDarkTheme = flag;
    },
    setBrand(state, brand) {
      state.brand = brand;
    },
    setBrands(state, brands) {
      state.brands = brands;
    },
    setPlatform(state, platform) {
      state.platform = platform;
    },
  },
  actions: {
    toggleMenu({ commit }, flag) {
      commit('toggleMenu', flag);
      flag ? Vue.prototype.$IOSBlockScroll() : Vue.prototype.$IOSReturnScroll();
    },
    async getBrandListRequest({ commit }) {
      try {
        const { data } = await Api.get({ requestUrl: '/brand/list' });

        commit('setBrands', data);
      } catch (error) {
        throw new Error(error);
      }
    },
    checkDevice({ commit }) {
      const setPlatform = () => {
        const width = window.innerWidth;
        if (width <= 768) commit('setPlatform', 'mobile');
        else if (width <= 1248) commit('setPlatform', 'tablet');
        else commit('setPlatform', 'desktop');
      };
      setTimeout(() => setPlatform(), 0);
      window.addEventListener('resize', () => {
        setPlatform();
      });
    },
  },
  getters: {
    isMobile: (state) => state.platform === 'mobile',
    isTablet: (state) => state.platform === 'tablet',
    isDesktop: (state) => state.platform === 'desktop',
  },
};
