export const BRANDS = [
  {
    name: 'MarfaTech',
    brand: 'Marfatech',
    icon: 'marfatech',
  },
  {
    name: 'Made of Storm',
    brand: 'Made of Storm',
    icon: 'duxgroup',
  },
];
